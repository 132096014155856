import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Assignments.module.css";
import { HeaderSection } from "@cpnw/ui";
import { DashboardAssignmentTable } from "components/DashboardAssignmentTable";
const Assignments = () => {

  const [assignments, setAssignments] = useState([
    { id: 1, name: "B,Bret", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "123-45-6789", userType: "EDUCATION", program: "TEST", location: "CPNW HEALTHCARE", startDate: "04/21/2024", endDate: "04/27/2024" },
    { id: 2, name: "B,Bret", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "987-65-4320", userType: "FACULTY", program: "TEST", location: "CPNW HEALTHCARE", startDate: "05/01/2024", endDate: "06/30/2024" },
    { id: 3, name: "D,Dawn", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "111-11-1111", userType: "STUDENT", program: "TEST", location: "CPNW HEALTHCARE", startDate: "03/26/2024", endDate: "06/29/2024" },
    { id: 4, name: "T,Thien", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "222-22-2222", userType: "STUDENT", program: "TEST", location: "CPNW HEALTHCARE", startDate: "03/26/2024", endDate: "06/29/2024" },
    { id: 5, name: "B,Bret", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "", userType: "STUDENT", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 6, name: "D,Dawn", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "333-33-3333", userType: "EDUCATION", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 7, name: "D,Dawn", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "", userType: "FACULTY", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 8, name: "Deckard,JoshS", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "234-23-2345", userType: "STUDENT", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 9, name: "Huynh,Bao Anh", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: null, userType: "STUDENT", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 10, name: "Huynh,Bao Anh", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "456-45-4567", userType: "STUDENT", program: "TEST", location: "CPNW HEALTHCARE", startDate: "06/29/2024", endDate: "09/29/2024" },
    { id: 11, name: "N,Trung", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "", userType: "EDUCATION", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 12, name: "Nguyen,DatT", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "", userType: "STUDENT", program: "TEST", location: "", startDate: "", endDate: "" },
    { id: 13, name: "T,Thien", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: "789-78-7890", userType: "EDUCATION", program: "TEST", location: "CPNW HEALTHCARE", startDate: "05/21/2024", endDate: "06/21/2024" },
    { id: 14, name: "T,Thien", email: "", phone: "", address: "", emergencyContact: "", emergencyContactPhone: "", sIDEID: null, userType: "FACULTY", program: "TEST", location: "", startDate: "", endDate: "" },
  ]);

  return (
    <section className={styles.dashboardAssignmentsPage}>
      <HeaderSection header={"Clinical Assignments"} />
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <p><a href="#">Clinical Site Affiliation Request</a></p>
        </div>
      </div>
      <hr />
      <div className={classNames(styles.row)}>
        <DashboardAssignmentTable assignments={assignments} setAssignments={setAssignments} />
      </div>
    </section>
  );
};

Assignments.propTypes = {

};

export default Assignments;

