import React, { useState } from 'react';
import { Button, HeaderSection } from '@cpnw/ui';
import DashboardDemographicsImage from 'components/DashboardDemographicsImage';
import styles from './DashboardMyDemographics.module.css';
import { CpnwContainer } from '../../../../hooks/useCpnw';

const DashboardMyDemographics = () => {
	const [imageFile, setImageFile] = useState(null);

	const { myDemoGraphicsData, submitDemoGraphics } =
		CpnwContainer.useContainer();

    //Add uploading state to prevent multiple uploads
	const [isUploading, setIsUploading] = useState(false);

	const saveDemographics = async () => {
		if (imageFile && !isUploading) {
            setIsUploading(true);
			const formData = new FormData();
			formData.append('files', imageFile);
			formData.append(
				'demographics',
				JSON.stringify({
					ContactIDPK: myDemoGraphicsData.contactIDPK,
					iRace: myDemoGraphicsData.iRace
				})
			);

			try {
				const result = await submitDemoGraphics(formData);
			} catch (error) {
				console.error('Submit DemoGraphics Photo error:', error);
			} finally {
				//reset image file to avoid reuploading the same image
				setImageFile(null);
				setIsUploading(false);
			}
		}
	};

	const handleImageChange = file => {
		setImageFile(file);
	};

	return (
		<div className={styles.dashboardMyDemographics}>
			<HeaderSection header='My Demographics'>
				My Demographics
			</HeaderSection>
			<p>Providing this information is optional.</p>
			<h3>School ID Photo</h3>
			<div className={styles.containerImage}>
				<DashboardDemographicsImage
					image={myDemoGraphicsData?.photoUrl ?? null}
					changeImageItem={handleImageChange}
				/>
			</div>
			<div>
				<hr />
			</div>
			<div className={styles.buttonContainer}>
				<Button
					className={styles.saveButton}
					text={'SAVE MY DEMOGRAPHICS'}
					onClick={saveDemographics}
				/>
			</div>
		</div>
	);
};

export default DashboardMyDemographics;
