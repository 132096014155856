import React from "react";
import styles from "./MyDashboardPage.module.css";
import { HeaderSection } from "@cpnw/ui";
import DashboardStepsList from "components/DashboardStepsList";
import DashboardNoticeItem from "components/DashboardNoticeItem";
import { StaticRoutes } from "pages/routes";
import { CpnwContainer } from "hooks/useCpnw";

const MyDashboardPage = () => {
  const {noticeLatestData} = CpnwContainer.useContainer()

  const steps = [
    {
      step: 1,
      info: "Review and complete all required content found in the web pages listed under the Clinical Prerequisites in the left column of this page.",
      link: {
        text: "Clinical Prerequisites",
      },
    },
    {
      step: 2,
      info: "The Clinical Passport page provides forms and information to complete and document all health and safety requirements that must be met before being assigned to a clinical setting.",
      link: {
        text: "Clinical Passport",
        src: StaticRoutes.Passport,
      },
    },
    {
      step: 3,
      info: "Site Requirements lists, by clinical site, additional requirements that must be met before coming to their location.",
      link: {
        text: "Site Requirements",
        src: StaticRoutes.SiteRequirements,
      },
    },
    {
      step: 4,
      info: "eLearning contains all the interactive training that must be completed for all clinical sites. Every user must complete and pass each training module annually. Your scores will be recorded in your My Records tab which can also be found on the left.",
      link: {
        text: "eLearning",
        src: StaticRoutes.eLearning,
      },
    },
  ];

  const notices = noticeLatestData ? noticeLatestData.NoticeContent : ""

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <HeaderSection header={"My Dashboard"} />
      </div>
      <div className={styles.pageContent}>
        <div className={styles.steps}>
          <h3>First Steps</h3>
          <DashboardStepsList steps={steps} />
        </div>
        <div className={styles.notices}>
          <h3>Notices</h3>
          <DashboardNoticeItem text={notices} />
          {/* {notices.map((notice, index) => (
            <div key={index}>
              <DashboardNoticeItem text={notice} />
              <br />
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default MyDashboardPage;
