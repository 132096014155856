import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./DashboardNavItem.module.css";
import { TipBox } from "@cpnw/ui";
import classNames from "classnames";

const DashboardNavItem = ({ name, src, tip }) => {

    const [hover, setHover] = useState(false);

    return (
        <>
            <div className={styles.navItem}>
                <Link to={src} 
                    className={styles.link}
                    onMouseEnter={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                >
                    {name}
                </Link>
            </div>
            <div className={classNames(styles.tipBoxContainer, hover && styles.hover)}>
                <TipBox tip={tip} />
            </div>
        </>
    )
}

export default DashboardNavItem;

DashboardNavItem.propTypes = {
    name: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    tip: PropTypes.string.isRequired
}