import React, { useCallback, forwardRef } from "react";
import { parse, format, isValid } from "date-fns";
import { InputBox } from "@cpnw/ui";
import styles from "./FormDatePicker.module.css";

export const FormDatePicker = forwardRef(
  (
    {
      dateString,
      setDate,
      label,
      dateFormat = "MM/dd/yyyy",
      minDateString,
      maxDateString,
      placeholder = "MM/dd/yyyy",
    },
    ref
  ) => {
    // Function to format dateString for the input box display
    const displayDate = dateString
      ? format(parse(dateString, dateFormat, new Date()), "yyyy-MM-dd")
      : "";
    const minDate = minDateString
      ? format(parse(minDateString, dateFormat, new Date()), "yyyy-MM-dd")
      : "";
    const maxDate = maxDateString
      ? format(parse(maxDateString, dateFormat, new Date()), "yyyy-MM-dd")
      : "";

    const handleDateChange = useCallback(
      (event) => {
        const newDate = event.target.value; // This is always in 'yyyy-MM-dd' because input type="date"
        const parsedDate = parse(newDate, "yyyy-MM-dd", new Date());
        if (isValid(parsedDate)) {
          // Using isValid from date-fns for clarity
          const formattedDate = format(parsedDate, dateFormat); // Convert to specified format for external use
          setDate(formattedDate);
        }
      },
      [setDate, dateFormat]
    );

    return (
      <div className={styles.container}>
        <InputBox
          id="date"
          label={label}
          type="date"
          placeholder={placeholder}
          value={displayDate}
          setValue={handleDateChange}
          min={minDate}
          max={maxDate}
          ref={ref}
        />
      </div>
    );
  }
);

export default FormDatePicker;
