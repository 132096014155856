import React, { useMemo, useEffect, useState } from "react";
import environment from "environment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Auth } from "../../auth";
import { StaticRoutes } from "pages/routes";
import { AdSpaceButton, Footer, NavMenu } from "@cpnw/ui";
import { AccountContainer } from "hooks/useAccount";
import { CpnwContainer } from "hooks/useCpnw";
import { AdminContainer } from "hooks/useAdmin";
import DashboardNavDropdown from "components/DashboardNavDropdown";

import styles from "./Layout.module.css";

export function Layout(props) {
  return (
    <Auth.Provider>
      <AccountContainer.Provider>
        <CpnwContainer.Provider>
          <AdminContainer.Provider>
            <LayoutWrapper>{props}</LayoutWrapper>
          </AdminContainer.Provider>
        </CpnwContainer.Provider>
      </AccountContainer.Provider>
    </Auth.Provider>
  );
}

function LayoutWrapper({ children }) {
  const history = useHistory();
  const { meData } = AccountContainer.useContainer();

  const isAccountRegistrationProfile =
    window.location.pathname === StaticRoutes.AccountRegistrationProfile;

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    if (meData && !initiated) {
      if (
        meData.regState == UserRegState.AwaitingBioCompletion ||
        meData.regState == UserRegState.FixBio
      ) {
        history.push(StaticRoutes.AccountRegistrationProfile);
      } else {
        if (isAccountRegistrationProfile) {
          history.push(StaticRoutes.MyDashboard);
        }
      }

      setInitiated(true);
    }
  }, [meData]);

  return initiated ? <LayoutContent {...children} /> : <h1>Loading...</h1>;
}

function LayoutContent({ children }) {
  const history = useHistory();
  const { logout, userRoles, conAssignments } = AccountContainer.useContainer();

  const isAccountRegistrationProfile =
    window.location.pathname === StaticRoutes.AccountRegistrationProfile;

  const [selectedContentNav, setSelectedContentNav] = useState(0);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openUrl = (url) => {
    history.push(url);
  };

  const onLogout = () => {
    logout();
  };

  const navMenus = [
    {
      label: "Logout",
      onClick: (e) => {
        onLogout();
      },
    },

    {
      label: "My Dashboard",
      onClick: (e) => {
        openUrl(StaticRoutes.MyDashboard);
      },
    },
  ];

  const navDropdownItems = [
    {
      label: "About Us",
      onClick: (e) => {
        openUrl(StaticRoutes.AboutUs);
      },
      pageList: [
        {
          label: "Healthcare Partners",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsHealthcarePartners);
          },
        },
        {
          label: "Participating Programs",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsParticipatingPrograms);
          },
        },
        {
          label: "Nursing Placement",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsNursingPlacement);
          },
        },
      ],
    },
    {
      label: "Our Services",
      onClick: (e) => {
        openUrl(StaticRoutes.OurServices);
      },
    },
    {
      label: "FAQ",
      onClick: (e) => {
        openUrl(StaticRoutes.FAQ);
      },
    },
    {
      label: "Get Started",
      onClick: (e) => {
        openUrl(StaticRoutes.GetStarted);
      },
      pageList: [
        {
          label: "Schools",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedSchools);
          },
        },
        {
          label: "Students",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedStudents);
          },
        },
        {
          label: "Healthcare",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedHealthcare);
          },
        },
      ],
    },
  ];

  const formattedNavDropdownItems = navDropdownItems.map((item) => {
    let newItem = { ...item };
    delete newItem.pageList;
    return newItem;
  });

  const hamburgerItems = [...navMenus, ...formattedNavDropdownItems];

  const leftNavigationMenus = useMemo(() => {
    if (conAssignments && conAssignments.length > 0) {
      return [...contentNavItems, ...adminMenus];
    }
    return contentNavItems;
  }, [conAssignments]);

  return (
    <div className={styles.container}>
      <NavMenu
        isStatic={true}
        onClickLogo={(e) => (window.location.href = environment.homeUrl)}
        navItems={navMenus}
        navDropdownItems={navDropdownItems}
        hamburgerItems={hamburgerItems}
      />
      {!isAccountRegistrationProfile && (
        <div className={styles.banner}>
          <AdSpaceButton
            size={"large"}
            onClick={() =>
              openInNewTab(
                "https://cpnw.blob.core.windows.net/documents/AdRotator/AdRequestFlyer-Form.pdf"
              )
            }
          />
        </div>
      )}
      <div className={styles.contentContainer}>
        {!isAccountRegistrationProfile && (
          <div className={styles.contentNav}>
            {leftNavigationMenus.map((item, index) => (
              <DashboardNavDropdown
                key={item.header}
                className={styles.contentNavItem}
                header={item.header}
                links={item.links}
                onClick={() =>
                  setSelectedContentNav(
                    index != selectedContentNav ? index : -1
                  )
                }
                droppedDown={selectedContentNav == index}
              />
            ))}
          </div>
        )}
        <div className={styles.children}>{children}</div>
      </div>
      <Footer />
    </div>
  );
}

const contentNavItems = [
  {
    header: "Clinical Prerequisites",
    links: [
      {
        name: "Clinical Passport",
        src: StaticRoutes.Passport,
        tip: "A fillable PDF",
      },
      {
        name: "Site Requirements",
        src: StaticRoutes.SiteRequirements,
        tip: "View additional requirements at your clinical site",
      },
      {
        name: "eLearning",
        src: StaticRoutes.eLearning,
        tip: "Complete eLearning Modules",
      },
    ],
  },
  {
    header: "My Records",
    links: [
      {
        name: "eLearning Results",
        src: StaticRoutes.eLearningResults,
        tip: "View your personal eLearning results",
      },
      {
        name: "OIG/SAM Check",
        src: StaticRoutes.OIGSAM,
        tip: "View your personal OIG/SAM results",
      },
    ],
  },
  {
    header: "My Account",
    links: [
      {
        name: "My Profile",
        src: StaticRoutes.MyProfile,
        tip: "View or change your profile information",
      },
      {
        name: "My Security Settings",
        src: StaticRoutes.MySecuritySettings,
        tip: "Change your preferences and update your password",
      },
      {
        name: "My Demographics",
        src: StaticRoutes.MyDemographics,
        tip: "Add a photo ID and choose to include other demographics ",
      },
    ],
  },
];

const adminMenus = [
  {
    header: "Admin",
    links: [
      {
        name: "Reports",
        src: StaticRoutes.Reports,
        tip: "Upload student documents and run OIG/SAM reports",
      },
      {
        name: "Assignments",
        src: StaticRoutes.Assignments,
        tip: "Assign students to a clinical facility",
      },
      {
        name: "Users",
        src: StaticRoutes.Users,
        tip: "General account management, including Approving and Declining accounts",
      },
    ],
  },
];

const UserRegState = {
  AwaitingBioCompletion: 1,
  FixBio: 4,
};

export default Layout;
