import env from "../environment";
import { token } from "../auth";
import { apiPath } from "./apiPath";

/**
 * Wraps a native fetch call with some predefined headers and
 * a resolved API path (if using a relative URL).
 *
 * @example
 * makeAuthedRequest("/example/items", { method: "get" })
 *  .then(res => {
 *    // Do something with res
 *  });
 *
 * @param {string} input - The path or URL to request.
 * @param {object} init - Options object forwarded to `fetch` function.
 * @param {string} apiBase - Base URL for API calls. Defaults to current apiBase from `environment.js`.
 */
export function makeAuthedRequest(input, init = {}, apiBase) {
  let path = input instanceof URL ? input.toString() : input;
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  apiBase = apiBase || (env && env.apiBase) || null;

  // Resolve relative paths against apiBase
  path = apiPath(path, apiBase);

  // Add credentials if we're going to our API
  if (path.startsWith(apiBase)) {
    headers["cpnw-token"] = token;
  }

  // Make the request with some preset headers.
  return fetch(path, {
    ...init,
    headers: {
      ...headers,
      ...(init.headers || {}),
    },
  });
}

export function makeAuthedMultipartRequest(input, formData, apiBase) {
  let path = input instanceof URL ? input.toString() : input;
  let headers = {
    Accept: "application/json",
    // "Content-Type": is set automatically by the browser when using FormData
  };

  apiBase = apiBase || (env && env.apiBase) || null;

  // Resolve relative paths against apiBase
  path = apiPath(path, apiBase);

  // Add credentials if we're going to our API
  if (path.startsWith(apiBase)) {
    headers["cpnw-token"] = token;
  }

  // Make the request with some preset headers.
  return fetch(path, {
    method: "POST",
    body: formData,
    headers: {
      ...headers,
    },
  });
}