import React, { useEffect, useMemo, useState } from 'react';
import styles from "./OIGSAMPage.module.css";

import { HeaderSection } from "@cpnw/ui";
import DashboardResultsTable from '../../../../components/DashboardResultsTable';
import { CpnwContainer } from 'hooks/useCpnw';
import DashboardOIGSAM from 'components/DashboardOIGSAM';

export default function OIGSAMPage(){
    const {oigSamData, oigSamRefetch} = CpnwContainer.useContainer()
    const [data, setData] = useState([])

    useEffect(()=>{
        oigSamRefetch()
    }, [])

    useEffect(()=>{
        if(oigSamData && oigSamData.length > 0){
            const results = oigSamData.map(a=>({
                scanDate: a.DateRun, 
                nameInput: a.NameInput, 
                agency: a.TableHIT, 
                scanResult: a.LikelyMatch, 
                message: a.Message, 
                nameFound: a.NameHit
            }))

            setData(results)
        }
        else{
            setData([])
        }
    }, [oigSamData])

return (
    <DashboardOIGSAM
      header={"OIG/SAM Excluded Provider Check"}
      infoLeft={
        <p>
          Excluded provider checks are performed bi-monthly on the 1st and 15th
          of each month for all users in the clinical setting. Your results will
          be posted on this page by the 5th and 20th of the month. The cost of
          this service is covered by your annual subscription fee.
          <br />
          <br />
          If there is a match of any kind, your school will contact you to
          further examine the finding.
        </p>
      }
      infoRight={
        <p>
          CPNW runs the screening against two federal excluded provider lists.
          The data sources for this check are:
          <br />
          <br />
          <ul>
            <li>
              Office of Inspector General&#40;OIG&#41; list of Excluded
              Individuals and Entities&#40;LEIE&#41;
            </li>
            <li>
              General Services Administration&#40;GSA&#41; System for Award
              Management&#40;SAM&#41;
            </li>
          </ul>
        </p>
      }
      items={data} // Ensure items prop is passed correctly
      itemWidth={{
        scanDateWidth: 300,
        nameInputWidth: 200,
        agencyWidth: 200,
        scanResultWidth: 200,
        messageWidth: 200,
        nameFoundWidth: 200,
      }} // Ensure itemWidth prop is passed correctly
    />
);
};