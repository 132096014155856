import React, { useMemo } from 'react';
import styles from "./ELearningResults.module.css";

import { HeaderSection } from "@cpnw/ui";
import DashboardResultsTable from '../../../../components/DashboardResultsTable';
import { CpnwContainer } from 'hooks/useCpnw';

export default function ELearningResults(){
    const {learningResultsData, myProfile} = CpnwContainer.useContainer()

    const data = useMemo(()=>{
        if(!learningResultsData){
            return []
        }

        return learningResultsData.map(a=>{
            return {
                subject: a.moduleName,
                status: a.moduleStatus,
                date: a.moduleTestedOn,
                style: a.moduleStyles
            }
        })
    }, [learningResultsData])

return (
    <section className={styles.e_learning_results_wrapper}>
        <HeaderSection header={"e-Learning Results"} />
        <p>When you successfully complete the eLearning modules your passing quiz score will be listed below. You may download a copy for your records.</p>
        {/* name, id, and src should be populated based on the user */}
        <DashboardResultsTable name={myProfile ? `${myProfile.NameLast}, ${myProfile.NameFirst}` : ""} id={myProfile ? myProfile.IndividualID : ""} src='#' results={data} />
    </section>
);
};