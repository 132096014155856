import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as XLSX from 'xlsx';
import styles from "./DashboardAssignmentTable.module.css";
import { Button, HeaderSection, Modal, SelectBox } from "@cpnw/ui";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import DashboardSearch from "components/DashboardSearch";
import DashboardTablePagination from "components/DashboardTablePagination";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

import { DashboardGroupAssigningModal } from "components/DashboardGroupAssigningModal";
import { DashboardAssignmentItem } from "components/DashboardAssignmentItem";

export const DashboardAssignmentTable = ({ assignments, setAssignments }) => {

    const DEFAULT_LOCATION = 'CPNW HEALTHCARE';
    const TITLE_COLUMN_NAME = 'Name';
    const TITLE_COLUMN_SIDEID = 'SID-EID';
    const TITLE_COLUMN_USER_TYPE = 'UserType';
    const TITLE_COLUMN_PROGRAM = 'Program';
    const TITLE_COLUMN_LOCATION = 'Location';
    const TITLE_COLUMN_START_DATE = 'Start Date';
    const TITLE_COLUMN_END_DATE = 'End Date';
    const TITLE_COLUMN_ADDEDIT = 'Add/Edit';

    const TITLE_COLUMN_EMAIL = 'Email';
    const TITLE_COLUMN_PHONE = 'Phone';
    const TITLE_COLUMN_ADDRESS = 'Address';
    const TITLE_COLUMN_EMERGENCY_CONTACT = 'Emergency Contact';
    const TITLE_COLUMN_EMERGENCY_CONTACT_PHONE = 'Emergency Contact Phone';
    const TITLE_COLUMN_SCHOOL = 'School';

    // Add _isEditing, _isShowAll, _isChecked, _isUnCheckedInSelecteMode properties to each assignment
    const [rows, setRows] = useState(assignments.map(assignment => ({
        ...assignment,
        _isEditing: false,
        _relatedTo: null,
        _isChecked: false,
        _isUnCheckedInSelecteMode: false
    })));

    const [filterName, setFilterName] = useState('');
    const [filterUserType, setFilterUserType] = useState('');
    const [filterProgram, setFilterProgram] = useState('');
    const [filterLocation, setFilterLocation] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');

    const [disableShowSelectedButton, setDisableShowSelectedButton] = useState(true);
    const [disableAssignGroupButton, setDisableAssignGroupButton] = useState(true);
    const [disableEditGroupButton, setDisableEditGroupButton] = useState(false);

    const [showSelectedOnly, setShowSelectedOnly] = useState(false);

    const [isOpenVideoTutorialModal, setOpenVideoTutorialModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState(undefined);
    const [isOpenGroupAssigningModal, setOpenGroupAssigningModal] = useState(false);
    const [isOpenEditGroupModal, setOpenEditGroupModal] = useState(false);

    const [sortingDirection, setSortingDirection] = useState('asc');
    const [sortingColumn, setSortingColumn] = useState(TITLE_COLUMN_NAME);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(assignments.length);
    const [limit, setLimit] = useState(10); // Default limit
    const limitOptions = [10, 25, 50, 100]; // Options for items per page
    const startIndex = (currentPage - 1) * limit;
    const endIndex = Math.min(startIndex + limit - 1, rows.length - 1);

    const scrollContainers = useRef([]);

    useEffect(() => {
        let updateAssignments = onFilter(filterName, filterUserType, filterProgram, filterLocation, filterStartDate);

        if (showSelectedOnly) {
            updateAssignments = updateAssignments.filter(assignment => assignment._isChecked === true || assignment._isUnCheckedInSelecteMode === true);
        }
        setRows(updateAssignments);

        const seletedAssignments = updateAssignments.filter(assignment => assignment._isChecked === true);
        // Check ShowSelectedButton disabled state
        const hasSelectedRows = seletedAssignments.length > 0;
        setDisableShowSelectedButton(!hasSelectedRows);

        // Check AssignGroupButton disabled state
        const hasMultipleSelectedRows = seletedAssignments.length > 1;
        setDisableAssignGroupButton(!hasMultipleSelectedRows);


        // Check EditGroupButton disabled state
        // Check if all assignments in selectedAssignments have the same location, startDate, and endDate
        const hasSameLocation = seletedAssignments.every(assignment => assignment.location === seletedAssignments[0].location);
        const hasSameStartDate = seletedAssignments.every(assignment => assignment.startDate === seletedAssignments[0].startDate);
        const hasSameEndDate = seletedAssignments.every(assignment => assignment.endDate === seletedAssignments[0].endDate);
        // Check if all three conditions are true
        const isEditGroupEnabled = ((seletedAssignments.length === 2) && hasSameLocation && hasSameStartDate && hasSameEndDate);
        setDisableEditGroupButton(!isEditGroupEnabled);

        setCurrentPage(1)
        setTotalItems(updateAssignments.length)

    }, [assignments, showSelectedOnly, filterName, filterUserType, filterProgram, filterLocation, filterStartDate]);

    const VideoPlayerButton = ({ label, videoUrl }) => {
        return <>
            <div>{label && <strong>{label}: </strong>}<a className={styles.videoPopup} onClick={() => onOpenVideoTutorialModal(true, videoUrl)}>
                <FontAwesomeIcon icon={faVideo} size="lg" /></a>
            </div>
        </>
    };

    const VideoTutorialModal = ({ isOpen, videoUrl }) => {
        const closeModal = () => {
            onCloseVideoTutorialModal()
        };

        return (
            <div>
                <Modal isOpen={isOpen} onRequestClose={closeModal} className={styles.customModal} children={<iframe
                    width="100%"
                    height="100%"
                    src={videoUrl}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>} />
            </div>
        );
    };
    const onCloseVideoTutorialModal = () => {
        setOpenVideoTutorialModal(false);
        setVideoUrl(undefined);
    }
    const onOpenVideoTutorialModal = (isOpen, videoUrl) => {
        setOpenVideoTutorialModal(isOpen);
        setVideoUrl(videoUrl);
    };

    const onCloseGroupAssigningModal = () => {
        setOpenGroupAssigningModal(false);
    }

    const onOpenGroupAssigningModal = (isOpen) => {
        setOpenGroupAssigningModal(isOpen);
    };

    const onGroupAssigning = (group) => {
        console.log(group);
        setOpenGroupAssigningModal(false);
    }

    const onCloseEditGroupModal = () => {
        setOpenEditGroupModal(false);
    }

    const onOpenEditGroupModal = (isOpen) => {
        setOpenEditGroupModal(isOpen);
    };

    const onEditGroup = (group) => {
        console.log(group);
        setOpenEditGroupModal(false);
    }

    const getEditGroupData = () => {
        const locationSelected = rows.filter(assignment => assignment._isChecked === true).map(row => row.location);
        const startDateSelected = rows.filter(assignment => assignment._isChecked === true).map(row => row.startDate);
        const endDateSelected = rows.filter(assignment => assignment._isChecked === true).map(row => row.endDate);
        const groupData = {
            location: (locationSelected.every(location => location === locationSelected[0]) ? locationSelected[0] : DEFAULT_LOCATION),
            startDate: (startDateSelected.every(location => location === startDateSelected[0]) ? startDateSelected[0] : ''),
            endDate: (endDateSelected.every(location => location === endDateSelected[0]) ? endDateSelected[0] : ''),
        }
        return groupData;
    }

    /** Function to get location options from assignments */
    const locationOptions = () => {
        // Get unique locations from assignments
        const uniqueLocations = assignments
            .map(row => row.location) // Extract location from each assignment
            .filter(Boolean); // Filter out falsy values (null, undefined, etc.)

        // Check if DEFAULT_LOCATION is already included in uniqueLocations
        const includesDefaultLocation = uniqueLocations.includes(DEFAULT_LOCATION);

        // If DEFAULT_LOCATION is not included, add it to the array
        if (!includesDefaultLocation) {
            uniqueLocations.push(DEFAULT_LOCATION);
        }

        // If there are locations, sort and return them, otherwise return default location
        return {
            options: [...new Set(uniqueLocations)].sort(),
            defaultValue: DEFAULT_LOCATION
        } // Return default location if no locations found
    };


    const onLimitChange = (newLimit) => {
        setLimit(newLimit);
        // Reset current page to 1 when the limit changes
        setCurrentPage(1)
    };

    const onDelete = (id) => {
        // Filter out the assignment with the specified ID
        const updatedAssignments = assignments.filter(assignment => assignment.id !== id);
        setAssignments(updatedAssignments);
        setRows(updatedAssignments);

    }

    const onDuplicate = (id) => {
        const currentRow = assignments.find(row => row.id === id);
        if (currentRow && (currentRow.location && currentRow.startDate && currentRow.endDate)) {
            const currentRowIndex = assignments.findIndex(row => row.id === id);
            if (currentRowIndex !== -1) {
                const maxId = Math.max(...assignments.map(assignment => assignment.id));
                const newRow = {
                    id: maxId + 1,
                    name: currentRow.name,
                    sIDEID: currentRow.sIDEID,
                    userType: currentRow.userType,
                    program: currentRow.program,
                    location: currentRow.location,
                    startDate: '',
                    endDate: '',
                    _isEditing: true,
                    _relatedTo: currentRow.id, //Add _relatedTo to check the row created base on another row
                    _isChecked: currentRow._isChecked,
                    _isUnCheckedInSelecteMode: currentRow._isUnCheckedInSelecteMode
                }
                const updatedRows = [
                    ...assignments.slice(0, currentRowIndex + 1), // Insert before current row
                    newRow,
                    ...assignments.slice(currentRowIndex + 1)
                ];

                setAssignments(updatedRows);
                setRows(updatedRows);
            }
        } else {
            currentRow._isEditing = true;
        }
    }

    /** Function to handle saving an assignment */
    const onSave = (saveAssignment) => {
        // Find the index of the assignment with id 14
        const indexToUpdate = assignments.findIndex(assignment => assignment.id === saveAssignment.id);

        if (indexToUpdate !== -1) {
            // Create a copy of the assignments array to avoid mutating state directly
            const updatedAssignments = [...assignments];

            // Update the isChecked for the assignment with id
            updatedAssignments[indexToUpdate] = {
                ...updatedAssignments[indexToUpdate], ...saveAssignment,
                _isEditing: false, // Replace isChecked with the desired new state
            };

            // Update the state with the new assignments array
            setAssignments(updatedAssignments);
            setRows(updatedAssignments);
        }
    };


    /** Function to filter assignments based on name, userType, program, location, startDate */
    const onFilter = (name, userType, program, location, startDate) => {
        return assignments.filter(assignment => {
            // Convert filter values and assignment properties to lowercase for case-insensitive comparison
            const assignmentName = assignment.name.toLowerCase();
            const assignmentUserType = assignment.userType.toLowerCase();
            const assignmentProgram = assignment.program.toLowerCase();
            const assignmentLocation = assignment.location.toLowerCase();
            const assignmentStartDate = assignment.startDate.toLowerCase();

            // Check if name matches
            if (name && !assignmentName.includes(name.toLowerCase())) {
                return false;
            }
            // Check if userType matches
            if (userType && !assignmentUserType.includes(userType.toLowerCase())) {
                return false;
            }
            // Check if program matches
            if (program && !assignmentProgram.includes(program.toLowerCase())) {
                return false;
            }
            // Check if startDate matches
            if (startDate && !assignmentStartDate.includes(startDate.toLowerCase())) {
                return false;
            }
            // Check if location matches
            if (location && assignmentLocation !== location.toLowerCase()) {
                return false;
            }
            // If all conditions pass, return true to include the assignment
            return true;
        });
    };

    const onSortChange = (columnName, direction) => {
        // Update sorting direction state for the respective column
        setSortingDirection(direction);
        setSortingColumn(columnName);
    };

    function onSelect(id, isChecked) {
        // Find the index of the assignment with id 14
        const indexToUpdate = assignments.findIndex(assignment => assignment.id === id);

        if (indexToUpdate !== -1) {
            // Create a copy of the assignments array to avoid mutating state directly
            const updatedAssignments = [...assignments];

            // Update the isChecked for the assignment with id
            updatedAssignments[indexToUpdate] = {
                ...updatedAssignments[indexToUpdate],
                _isChecked: isChecked, // Replace isChecked with the desired new state
                _isUnCheckedInSelecteMode: (showSelectedOnly && !isChecked) ? true : false,
            };

            // Update the state with the new assignments array
            setAssignments(updatedAssignments);
        } else {
            console.error(`Assignment with id ${id} not found.`);
        }
    }

    // show only the selected items when the "Show Selected" button is clicked
    const onShowSelected = () => {
        setShowSelectedOnly(true);
    };

    // show all items when the "Show All" button is clicked
    const onShowAll = () => {
        setShowSelectedOnly(false);
        let updateAssignments = assignments.map(assignment => ({
            ...assignment,
            _isUnCheckedInSelecteMode: false
        }))
        setAssignments(updateAssignments);

    };

    const onExportToExcel = () => {
        const headers = [
            TITLE_COLUMN_EMAIL,
            TITLE_COLUMN_PHONE,
            TITLE_COLUMN_ADDRESS,
            TITLE_COLUMN_EMERGENCY_CONTACT,
            TITLE_COLUMN_EMERGENCY_CONTACT_PHONE,
            TITLE_COLUMN_SCHOOL,
            TITLE_COLUMN_NAME,
            TITLE_COLUMN_SIDEID,
            TITLE_COLUMN_USER_TYPE,
            TITLE_COLUMN_PROGRAM,
            TITLE_COLUMN_LOCATION,
            TITLE_COLUMN_START_DATE,
            TITLE_COLUMN_END_DATE
        ];

        const wb = XLSX.utils.book_new();
        const wsData = [headers, ...(showSelectedOnly ? assignments.filter(assignment => assignment._isChecked === true) : assignments)
            .slice(0).map(item => [
                item.email,
                item.phone,
                item.address,
                item.emergencyContact,
                item.emergencyContactPhone,
                item.school,
                item.name,
                item.sIDEID,
                item.userType,
                item.program,
                item.location,
                item.startDate,
                item.endDate])];
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Adjust column widths
        const columnWidths = [
            { wpx: 150 }, // Email column width
            { wpx: 100 }, // Phone column width
            { wpx: 200 }, // Address column width
            { wpx: 150 }, // Emergency Contact column width
            { wpx: 150 }, // Emergency Contact Phone column width
            { wpx: 150 }, // School column width
            { wpx: 150 }, // Name column width
            { wpx: 100 }, // SID-EID column width
            { wpx: 100 }, // UserType column width
            { wpx: 150 }, // Program column width
            { wpx: 150 }, // Location column width
            { wpx: 100 }, // Start Date column width
            { wpx: 100 }  // End Date column width
        ];
        ws['!cols'] = columnWidths;

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'Assignments - CPNW.xlsx');
    }

    const onPageChange = (e) => {
        setCurrentPage(e);
    }

    const handleScroll = (index) => {
        const scrollLeft = scrollContainers.current[index].scrollLeft;
        scrollContainers.current.forEach((container, i) => {
            if (i !== index) {
                container.scrollLeft = scrollLeft;
            }
        });
    };

    const replaceNullValue = (value) => {
        return (value ? value : "");
    }

    return (
        <div className={styles.dashboardAssignmentTable}>
            <div className={classNames(styles.row)}>
                <div className={classNames(styles.column)}>
                    <h5 className={styles.assignmentTutorialsTitle}>Assignment Tutorials:</h5>
                </div>
            </div>
            <div className={classNames(styles.assignmentTutorials, styles.row)}>
                <div className={classNames(styles.column)}>
                    <VideoPlayerButton label={'Adding'} videoUrl={"https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/addingAssignment.mp4"} />
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <VideoPlayerButton label={'Editing'} videoUrl={"https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/editingAssignment.mp4"} />
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <VideoPlayerButton label={'Deleting'} videoUrl={"https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/deletingAssignment.mp4"} />
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <a href="https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/CoordinatorChecklist.pdf" target="_blank">Coordinator Checklist (PDF)</a>
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <a href="https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/BatchAssignmentInstructions.pdf" target="_blank">Batch Assigning (PDF) </a>
                </div>
            </div>
            <div className={classNames(styles.assignmentActionsRow, styles.row)} >
                <div className={classNames(styles.column)}>
                    <DashboardItemsPerPage disabled={false} limit={limit} limitOptions={limitOptions} onLimitChange={onLimitChange} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onShowAll()} text="Show All" disabled={false} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onShowSelected()} text="Show Selected" disabled={disableShowSelectedButton} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onExportToExcel()} text="Export" disabled={false} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onOpenGroupAssigningModal(true)} text="Assign Group" disabled={disableAssignGroupButton} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onOpenEditGroupModal(true)} text="Edit Group" disabled={disableEditGroupButton} />
                </div>
                <div className={classNames(styles.dashboardSearch, styles.column)}>
                    <DashboardSearch onInput={(input) => setFilterName(input)} searchDelay={0} minChar={0} />
                </div>
            </div>
            <div className={styles.container}>
                <div className={classNames(styles.scrollContainer, styles.assignmentHeader)} style={{ overflow: 'hidden' }} ref={(el) => (scrollContainers.current[0] = el)}
                    onScroll={() => handleScroll(0)}>
                    <div className={classNames(styles.columnCheckBox, styles.content)}>&nbsp;&nbsp;</div>
                    <div className={classNames(styles.columnIndex0, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_NAME} value={(sortingColumn == TITLE_COLUMN_NAME) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_NAME, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex1, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_SIDEID} value={(sortingColumn == TITLE_COLUMN_SIDEID) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_SIDEID, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex2, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_USER_TYPE} value={(sortingColumn == TITLE_COLUMN_USER_TYPE) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_USER_TYPE, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex3, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_PROGRAM} value={(sortingColumn == TITLE_COLUMN_PROGRAM) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_PROGRAM, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex4, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_LOCATION} value={(sortingColumn == TITLE_COLUMN_LOCATION) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_LOCATION, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex5, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_START_DATE} value={(sortingColumn == TITLE_COLUMN_START_DATE) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_START_DATE, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex6, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_END_DATE} value={(sortingColumn == TITLE_COLUMN_END_DATE) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_END_DATE, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex7, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_ADDEDIT} value={(sortingColumn == TITLE_COLUMN_ADDEDIT) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_ADDEDIT, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                </div>
                <div className={styles.scrollContainer} ref={(el) => (scrollContainers.current[1] = el)}
                    onScroll={() => handleScroll(1)}>
                    {
                        rows
                            .slice(startIndex, endIndex + 1) // Create a copy of the rows array to avoid mutating the original array
                            .sort((a, b) => {
                                // Implement sorting based on the sorting direction and column
                                switch (sortingDirection) {
                                    case 'asc':
                                        switch (sortingColumn) {
                                            case TITLE_COLUMN_NAME:
                                                return (replaceNullValue(a.name)).localeCompare(replaceNullValue(b.name));
                                            case TITLE_COLUMN_SIDEID:
                                                return (replaceNullValue(a.sIDEID)).localeCompare(replaceNullValue(b.sIDEID));
                                            case TITLE_COLUMN_USER_TYPE:
                                                return (replaceNullValue(a.userType)).localeCompare(replaceNullValue(b.userType));
                                            case TITLE_COLUMN_PROGRAM:
                                                return (replaceNullValue(a.program)).localeCompare(replaceNullValue(b.program));
                                            case TITLE_COLUMN_LOCATION:
                                                return (replaceNullValue(a.location)).localeCompare(replaceNullValue(b.location));
                                            case TITLE_COLUMN_START_DATE:
                                                return (replaceNullValue(a.startDate)).localeCompare(replaceNullValue(b.startDate));
                                            case TITLE_COLUMN_END_DATE:
                                                return (replaceNullValue(a.endDate)).localeCompare(replaceNullValue(b.endDate));
                                            case TITLE_COLUMN_ADDEDIT:
                                                return (replaceNullValue(a.location) + replaceNullValue(a.startDate) + replaceNullValue(a.endDate)).localeCompare(replaceNullValue(b.location) + replaceNullValue(b.startDate) + replaceNullValue(b.endDate));
                                            default:
                                                return (replaceNullValue(a.name)).localeCompare(replaceNullValue(b.name));
                                        }
                                    case 'desc':
                                        switch (sortingColumn) {
                                            case TITLE_COLUMN_NAME:
                                                return (replaceNullValue(b.name)).localeCompare(replaceNullValue(a.name));
                                            case TITLE_COLUMN_SIDEID:
                                                return (replaceNullValue(b.sIDEID)).localeCompare(replaceNullValue(a.sIDEID));
                                            case TITLE_COLUMN_USER_TYPE:
                                                return (replaceNullValue(b.userType)).localeCompare(replaceNullValue(a.userType));
                                            case TITLE_COLUMN_PROGRAM:
                                                return (replaceNullValue(b.program)).localeCompare(replaceNullValue(a.program));
                                            case TITLE_COLUMN_LOCATION:
                                                return (replaceNullValue(b.location)).localeCompare(replaceNullValue(a.location));
                                            case TITLE_COLUMN_START_DATE:
                                                return (replaceNullValue(b.startDate)).localeCompare(replaceNullValue(a.startDate));
                                            case TITLE_COLUMN_END_DATE:
                                                return (replaceNullValue(b.endDate)).localeCompare(replaceNullValue(a.endDate));
                                            case TITLE_COLUMN_ADDEDIT:
                                                return (replaceNullValue(b.location) + replaceNullValue(b.startDate) + replaceNullValue(b.endDate)).localeCompare(replaceNullValue(a.location) + replaceNullValue(a.startDate) + replaceNullValue(a.endDate));
                                            default:
                                                return (replaceNullValue(b.name)).localeCompare(replaceNullValue(a.name));
                                        }
                                    default:
                                        return 0;
                                }
                            })
                            .map((assignment, index) => (
                                <DashboardAssignmentItem
                                    key={assignment.id}
                                    selected={assignment._isChecked}
                                    onSelect={onSelect}
                                    assignment={assignment}
                                    onSave={onSave}
                                    onDelete={onDelete}
                                    onDuplicate={onDuplicate}
                                    isEditing={assignment._isEditing}
                                    backgroundClassName={(index % 2 !== 0) ? styles.evenRow : undefined}
                                    locationOptions={locationOptions()}
                                />
                            ))
                    }
                </div>
                <div className={classNames(styles.scrollContainer, styles.assignmentFooter)} style={{ overflow: 'hidden' }} ref={(el) => (scrollContainers.current[2] = el)}
                    onScroll={() => handleScroll(2)}>
                    <div className={classNames(styles.assignmentFilterRow, styles.rowFilter, styles.content)} style={{ display: 'flex' }}>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) =>
                                setFilterUserType(value)
                            } placeholder={""} options={[...new Set(assignments.map(item => item.userType))].filter(Boolean).sort()} />
                        </div>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) =>
                                setFilterProgram(value)
                            } placeholder={""} options={[...new Set(assignments.map(item => item.program))].filter(Boolean).sort()} />
                        </div>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) =>
                                setFilterLocation(value)
                            } placeholder={""} options={locationOptions().options} />
                        </div>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) =>
                                setFilterStartDate(value)
                            } placeholder={""} options={[...new Set(assignments.map(item => item.startDate))].filter(Boolean).sort()} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classNames(styles.assignmentPaginationRow, styles.row)}>
                <div className={classNames(styles.columnPagination, styles.column)}>
                    <DashboardTablePagination onPageChange={(e) => onPageChange(e)} total={totalItems}
                        perPage={limit} />
                </div>
            </div>
            <VideoTutorialModal isOpen={isOpenVideoTutorialModal} videoUrl={videoUrl} />
            <DashboardGroupAssigningModal isOpen={isOpenGroupAssigningModal} onClose={() => onCloseGroupAssigningModal()} locationOptions={locationOptions()} onAssign={(group) => { onGroupAssigning(group) }} />
            <DashboardGroupAssigningModal isOpen={isOpenEditGroupModal} onClose={() => onCloseEditGroupModal()} locationOptions={locationOptions()} onAssign={(group) => { onEditGroup(group) }} isEditGroup={true} groupData={getEditGroupData()} />
        </div>
    );
};

DashboardAssignmentTable.propTypes = {
    assignments: PropTypes.array.isRequired,
};


export default DashboardAssignmentTable;

