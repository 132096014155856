import React from "react";
import styles from "./DashboardNoticeItem.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DashboardNoticeItem = ({ text }) => {

    const Html = { __html: text }

    return (
        <div dangerouslySetInnerHTML={Html}/>
    )
}

export default DashboardNoticeItem;

DashboardNoticeItem.propTypes = {
    text: PropTypes.string.isRequired
}