import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardAssignmentItem.module.css";
import { Modal, CheckboxItem, SelectBox} from "@cpnw/ui";
// import {CheckboxItem} from "components/CheckboxItem";
// import { SelectBox } from "components/SelectBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../images/preloader.gif";
import FormDatePicker from "components/FormDatePicker";
import { DashboardContactInformationModal } from "components/DashboardContactInformationModal";


export const DashboardAssignmentItem = ({ selected, onSelect, assignment, onSave, onDelete, onDuplicate, isEditing, backgroundClassName, locationOptions }) => {
    const [data, setData] = useState(assignment);
    const [startDate, setStartDate] = useState(data.startDate);
    const [endDate, setEndDate] = useState(data.endDate);
    const [location, setLocation] = useState(data.location);
    const [editMode, setEditMode] = useState(isEditing);
    const [showPreloader, setShowPreloader] = useState(false);
    const [isChecked, setChecked] = useState(selected);
    const [isOpenContactInfoModal, setOpenContactInfoModal] = useState(false);

    const onCloseContactInfoModal = () => {
        setOpenContactInfoModal(false);
    }

    const onOpenContactInfoModal = (isOpen) => {
        setOpenContactInfoModal(isOpen);
    };

    const handleSelect = (id, isChecked) => {
        setChecked(isChecked);
        onSelect(id, isChecked);
    }

    const handleDelete = () => {
        setShowPreloader(true);
        const timeoutId = setTimeout(() => {
            setShowPreloader(false);
            clearTimeout(timeoutId);
            onDelete(data.id);
        }, 2000);
    }

    const handleEdit = () => {
        setLocation((data.location !== undefined && data.location !== null && data.location !== "") ? data.location : locationOptions.defaultValue);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        setEditMode(true);
    }

    const handleDuplicate = () => {
        if (validate()) {
            onDuplicate(data.id);
        } else {
            handleEdit();
        }
    }

    const handleSave = () => {
        if (validate()) {
            setShowPreloader(true);
            const timeoutId = setTimeout(() => {
                setShowPreloader(false);
                clearTimeout(timeoutId);
                if (data._relatedTo) {
                    delete data._relatedTo;
                }
                data.location = location;
                data.startDate = startDate;
                data.endDate = endDate;
                handleCancelEdit(data)
                onSave(data)

            }, 2000);
            console.log(data);
        }
    }

    const handleCancelEdit = () => {
        if (data._relatedTo) {
            onDelete(data.id);
        } else {
            setData(data)
            setEditMode(false);
        }
        setLocation((data.location !== undefined && data.location !== null && data.location !== "") ? data.location : locationOptions.defaultValue);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
    }

    const validate = () => {
        if ((location !== undefined && location !== null && location !== '') && startDate && endDate) {
            return true;
        }
        return false;
    }

    const isDateAfter = (startDate, endDate) => {
        // Convert string dates to Date objects
        const sDate = new Date(startDate);
        const eDate = new Date(endDate);

        // Check if sDate is after eDate
        return sDate > eDate;
    };

    useEffect(() => {
        if (isDateAfter(startDate, endDate)) {
            setEndDate(startDate);
        }
    }, [startDate, endDate]);

    return (
        <div className={classNames(styles.rowData, styles.row, isChecked && styles.selectedRow, !isChecked && backgroundClassName)}>
            <div className={classNames(styles.columnCheckBox, styles.content)}>
                <CheckboxItem onClick={(isChecked) => handleSelect(data.id, isChecked)} checkedIcon={styles.customCheckedIcon} uncheckedIcon={styles.customUncheckedIcon}
                    isChecked={isChecked} readOnly={assignment._relatedTo ? true : false}
                    className={isChecked ? styles.selectedIcon : styles.unSelectIcon} /></div>
            <div className={classNames(styles.columnIndex0, styles.content)}>
                <a style={{ textDecoration: "underline" }} href="#" onClick={() => onOpenContactInfoModal(true)}>{data.name}</a></div>
            <div className={classNames(styles.columnIndex1, styles.content)}>{data.sIDEID}</div>
            <div className={classNames(styles.columnIndex2, styles.content)}>{data.userType}</div>
            <div className={classNames(styles.columnIndex3, styles.content)}>{data.program}</div>
            <div className={classNames(styles.columnIndex4, styles.content)}>
                {editMode ? (
                    <SelectBox
                        selected={location}
                        onChange={(value) => {
                            setLocation(value);
                        }}
                        placeholder={""}
                        options={locationOptions.options}
                    />
                ) : (
                    <div>{data.location}</div>
                )}</div>
            <div className={classNames(styles.columnIndex5, styles.content)}>
                {editMode ? (
                    <FormDatePicker
                        placeholder={"MM/DD/YYYY"}
                        setDate={(value) => {
                            setStartDate(value);
                        }}
                        dateString={startDate}
                        dateFormat='MM/dd/yyyy'
                    />
                ) : (
                    <div>{data.startDate}</div>
                )}</div>
            <div className={classNames(styles.columnIndex6, styles.content)}>
                {editMode ? (
                    <FormDatePicker
                        placeholder={"MM/DD/YYYY"}
                        setDate={(value) => {
                            setEndDate(value);
                        }}
                        dateString={endDate}
                        dateFormat='MM/dd/yyyy'
                        minDateString={startDate}
                    />
                ) : (
                    <div>{data.endDate}</div>
                )}</div>
            <div className={classNames(styles.columnIndex7, styles.content)}>
                {editMode ? (<>
                    {showPreloader ? <div className={styles.preloader}><img src={Preloader} height="35" width="35" /></div> :
                        <>
                            <a onClick={() => handleSave()} className={classNames(styles.assignmentSave, styles.assignmentAction, isChecked && styles.selectedIcon)}><FontAwesomeIcon icon={faSave} size="xl" /></a>
                            <a onClick={() => handleCancelEdit()} className={classNames(styles.assignmentCancel, styles.assignmentAction, isChecked && styles.selectedIcon)}><FontAwesomeIcon icon={faTimes} size="xl" /></a>
                        </>
                    }
                </>
                ) : (
                    data.location ? (<>
                        {showPreloader ? <div className={styles.preloader}><img src={Preloader} height="35" width="35" /></div> :
                            <>
                                <a onClick={() => handleDuplicate()} className={classNames(styles.assignmentAdd, styles.assignmentAction, isChecked && styles.selectedIcon)}><FontAwesomeIcon icon={faPlusSquare} size="xl" /></a>
                                <a onClick={() => handleEdit()} className={classNames(styles.assignmentEdit, styles.assignmentAction, isChecked && styles.selectedIcon)}><FontAwesomeIcon icon={faEdit} size="xl" /></a>
                                <a onClick={() => handleDelete()} className={classNames(styles.assignmentDelete, styles.assignmentAction, isChecked && styles.selectedIcon)}><FontAwesomeIcon icon={faTrash} size="xl" /></a>
                            </>
                        }
                    </>) : (<>
                        <a onClick={() => handleDuplicate()} className={classNames(styles.assignmentAdd, styles.assignmentAction, isChecked && styles.selectedIcon)}><FontAwesomeIcon icon={faPlusSquare} size="xl" /></a>
                    </>)
                )}
            </div>
            <DashboardContactInformationModal isOpen={isOpenContactInfoModal} onClose={() => { onCloseContactInfoModal() }} contact={assignment} />
        </div>
    );
};

DashboardAssignmentItem.propTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    assignment: PropTypes.object,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onDuplicate: PropTypes.func,
    isEditing: PropTypes.bool,
    backgroundClassName: PropTypes.string,
    locationOptions: PropTypes.object,
}

export default DashboardAssignmentItem;


