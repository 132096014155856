import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";
import { format } from "date-fns";
import { InputBox, InfoSection, Button } from "@cpnw/ui";
import { CpnwContainer } from "hooks/useCpnw";
import { StaticRoutes } from "pages/routes";
import FormDatePicker from "components/FormDatePicker";
import SocialSecurityInput from "components/DashboardSocialInput";
import QuestionToggle from "components/QuestionToggle";

import styles from "./DashboardMyProfile.module.css";

const MyProfile = ({
  initialValues = {
    firstName: "",
    lastName: "",
    middleInitial: "",
    email: "",
    altEmail: "",
    primaryPhone: "",
    studentFacultyID: "",
    school: "",
    program: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    address1: "",
    city: "",
    state: "",
    zipcode: "",
    dateOfBirth: "",
    socialSecurity: "",
    previousName: false,
    previousNames: [],
  },
}) => {
  const { myProfile, refetchMyProfile, myProfilePost } =
    CpnwContainer.useContainer();

  const isAccountRegistrationProfile =
    window.location.pathname === StaticRoutes.AccountRegistrationProfile;

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const middleInitialRef = useRef(null);
  const altEmailRef = useRef(null);
  const primaryPhoneRef = useRef(null);
  const individualIDRef = useRef(null);
  const emergencyContactNameRef = useRef(null);
  const emergencyContactPhoneRef = useRef(null);
  const address1Ref = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipRef = useRef(null);
  const dobRef = useRef(null);
  const ssnRef = useRef(null);

  const [firstName, setFirstName] = useState(initialValues.firstName);
  const [lastName, setLastName] = useState(initialValues.lastName);
  const [middleInitial, setMiddleInitial] = useState(
    initialValues.middleInitial
  );
  const [email, setEmail] = useState(initialValues.email);
  const [altEmail, setAltEmail] = useState(initialValues.altEmail);
  const [primaryPhone, setPrimaryPhone] = useState(initialValues.primaryPhone);
  const [individualID, setIndividualID] = useState(
    initialValues.studentFacultyID
  );
  const [school, setSchool] = useState(initialValues.school);
  const [program, setProgram] = useState(initialValues.program);
  const [emergencyContact, setEmergencyContactName] = useState(
    initialValues.emergencyContactName
  );
  const [emergencyPhone, setEmergencyContactPhone] = useState(
    initialValues.emergencyContactPhone
  );
  const [address1, setAddress1] = useState(initialValues.address1);
  const [city, setCity] = useState(initialValues.city);
  const [state, setState] = useState(initialValues.state);
  const [zip, setZip] = useState(initialValues.zipcode);
  const [dob, setDOB] = useState(initialValues.dateOfBirth);
  const [ssn, setSSN] = useState(initialValues.socialSecurity);
  const [hasPreviousNames, setHasPreviousNames] = useState(
    initialValues.previousNames?.length > 0
  );
  const [previousNames, setPreviousNames] = useState(
    initialValues.previousNames
  );

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [errors, setErrors] = useState({});
  const [initiated, setInitiated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);

  useEffect(() => {
    if (myProfile) {
      if (!initiated) setInitiated(true);

      setEmail(myProfile.Email);
      setSchool(myProfile.FacilityName);
      setProgram(myProfile.ProgramName);

      let dirty = initiated ? checkDirty(myProfile) : false;
      setDirty(dirty);

      if (!dirty) {
        setFirstName(myProfile.NameFirst);
        setLastName(myProfile.NameLast);
        setMiddleInitial(myProfile.MidInitial);
        setAltEmail(myProfile.AltEmail);
        setPrimaryPhone(myProfile.PrimaryPhone);
        setIndividualID(myProfile.IndividualID);

        setEmergencyContactName(myProfile.EmergencyContact);
        setEmergencyContactPhone(myProfile.EmergencyPhone);
        setAddress1(myProfile.Address1);
        setCity(myProfile.City);
        setState(myProfile.State);
        setZip(myProfile.Zip);
        setDOB(myProfile.DOB ? format(myProfile.DOB, dateFormat) : "");
        setSSN(myProfile.SSN);
        setHasPreviousNames(myProfile.PreviousNames?.length > 0);
        setPreviousNames(
          myProfile.PreviousNames
            ? [...myProfile.PreviousNames.map((m) => ({ ...m }))]
            : []
        );
      }
    }
  }, [myProfile]);

  useEffect(() => {
    let dirty = initiated ? checkDirty(myProfile) : false;
    setDirty(dirty);

    if (submittedOnce) validate();
  }, [
    firstName,
    lastName,
    middleInitial,
    altEmail,
    primaryPhone,
    individualID,
    emergencyContact,
    emergencyPhone,
    address1,
    city,
    state,
    zip,
    dob,
    ssn,
    hasPreviousNames,
    previousNames,
  ]);

  const checkDirty = (profileData) => {
    let stringProfilePrevs = (profileData.PreviousNames || [])
      .map((m) => `${m.NameFirst || ""}${m.NameLast || ""}`.toLowerCase())
      .join(",");

    let stringNewPreves = hasPreviousNames
      ? (previousNames || [])
          .map((m) => `${m.NameFirst || ""}${m.NameLast || ""}`.toLowerCase())
          .join(",")
      : "";

    let same =
      profileData.NameFirst == firstName &&
      profileData.NameLast == lastName &&
      profileData.MidInitial == middleInitial &&
      profileData.AltEmail == altEmail &&
      profileData.PrimaryPhone == primaryPhone &&
      profileData.IndividualID == individualID &&
      profileData.EmergencyContact == emergencyContact &&
      profileData.EmergencyPhone == emergencyPhone &&
      profileData.Address1 == address1 &&
      profileData.City == city &&
      profileData.State == state &&
      profileData.Zip == zip &&
      (profileData.DOB ? format(profileData.DOB, dateFormat) : "") == dob &&
      profileData.SSN == ssn &&
      stringProfilePrevs == stringNewPreves;
    return !same;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmittedOnce(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    if (validate()) {
      let data = {
        NameFirst: firstName,
        NameLast: lastName,
        MidInitial: middleInitial,
        AltEmail: altEmail,
        PrimaryPhone: primaryPhone,
        IndividualID: individualID,
        EmergencyContact: emergencyContact,
        EmergencyPhone: emergencyPhone,
        Address1: address1,
        City: city,
        State: state,
        Zip: zip,
        DOB: dob ? moment(dob).format("YYYY-MM-DD") : "",
        SSN: ssn,
        Aliases: hasPreviousNames ? previousNames : [],
      };

      let result = null;
      try {
        setLoading(true);
        result = await myProfilePost(data);
        if (result) {
          setSuccessMessage(result.message);
          setDirty(false);
          setInitiated(false);
          if (isAccountRegistrationProfile)
            window.location.href = StaticRoutes.MyDashboard;
          else refetchMyProfile();
        }
      } catch (error) {
        setErrorMessage(
          error.errors && error.errors.length > 0
            ? error.errors[0]
            : error.message
        );
      }
      setLoading(false);
    }
  };

  const addPrevious = (e) => {
    e.preventDefault();

    if (previousNames.length < 50) {
      setPreviousNames([...previousNames, { NameFirst: null, NameLast: null }]);
    }
  };

  const handlePreviousItemChange = (name, index, value) => {
    const newPrevious = [...previousNames];

    newPrevious[index][name] = value;

    setPreviousNames([...newPrevious]);
  };

  const validate = () => {
    let newErrors = {};
    let noError = true;

    if (!firstName) {
      newErrors.firstName = "The Legal First Name field is required.";
      firstNameRef.current.focus();
      noError = false;
    }
    if (!lastName) {
      newErrors.lastName = "The Legal Last Name field is required.";
      lastNameRef.current.focus();
      noError = false;
    }

    if (!primaryPhone) {
      newErrors.primaryPhone = "The Phone field is required.";
      primaryPhoneRef.current.focus();
      noError = false;
    }

    if (
      altEmail &&
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        altEmail
      )
    ) {
      newErrors.altEmail = "Alternative Email is not valid.";
      altEmailRef.current.focus();
      noError = false;
    }

    if (!individualID) {
      newErrors.studentFacultyID = "The Student ID field is required.";
      individualIDRef.current.focus();
      noError = false;
    }
    if (!emergencyContact) {
      newErrors.emergencyContactName =
        "The Emergency Contact field is required.";
      emergencyContactNameRef.current.focus();
      noError = false;
    }
    if (!emergencyPhone) {
      newErrors.emergencyContactPhone =
        "The Emergency Contact Phone field is required.";
      emergencyContactPhoneRef.current.focus();
      noError = false;
    }
    if (!address1) {
      newErrors.permanentAddress = "The Address field is required.";
      address1Ref.current.focus();
      noError = false;
    }
    if (!city) {
      newErrors.city = "The City field is required.";
      cityRef.current.focus();
      noError = false;
    }
    if (!state) {
      newErrors.state = "The State field is required.";
      stateRef.current.focus();
      noError = false;
    }
    if (!zip) {
      newErrors.zipcode = "The Zipcode field is required.";
      zipRef.current.focus();
      noError = false;
    }
    if (!dob) {
      newErrors.dateOfBirth = "The Date of Birth field is required.";
      dobRef.current.focus();
      noError = false;
    }

    if (!ssn) {
      newErrors.socialSecurity =
        "The Social Security Number field is required.";
      ssnRef.current.focus();
      noError = false;
    }

    setErrors(newErrors);

    return noError;
  };

  return (
    <div className={styles.container} disabled={initiated}>
      <form onSubmit={handleSubmit} className={styles.layout}>
        <div className={styles.mainContainer}>
          <div className={styles.infoSection}>
            <InfoSection header="My Profile" paragraphs={paragraphText} />
            <small>* required</small>
            {successMessage && !dirty && (
              <p
                className={classNames(
                  styles.success,
                  styles.round,
                  styles.label
                )}
              >
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p
                className={classNames(styles.error, styles.round, styles.label)}
              >
                {errorMessage}
              </p>
            )}
          </div>
          <div className={styles.controlsSection}>
            <div className={styles.inputRow}>
              <div className={classNames([styles.inputBox, styles.wp40])}>
                <InputBox
                  ref={firstNameRef}
                  id="firstName"
                  htmlFor="firstName"
                  placeholder="First Name"
                  label="First Name *"
                  value={firstName}
                  setValue={(event) => setFirstName(event.target.value)}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.firstName}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp40])}>
                <InputBox
                  placeholder="Last Name"
                  label="Last Name *"
                  id="lastName"
                  htmlFor="lastName"
                  value={lastName}
                  setValue={(event) => setLastName(event.target.value)}
                  ref={lastNameRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.lastName}
                  </span>
                </small>
              </div>
              <div className={`${styles.inputBox} ${styles.wp20}`}>
                <InputBox
                  placeholder="MI"
                  label="Middle Initial"
                  id="middleInitial"
                  htmlFor="middleInitial"
                  value={middleInitial}
                  setValue={(event) => setMiddleInitial(event.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={classNames([styles.inputBox, styles.wp50])}>
                <InputBox
                  placeholder="email@example.com"
                  label="Email/Username"
                  id="email"
                  htmlFor="email"
                  readOnly
                  value={email}
                  setValue={(event) => setEmail(event.target.value)}
                />
              </div>
              <div className={classNames([styles.inputBox, styles.wp50])}>
                <InputBox
                  ref={altEmailRef}
                  placeholder="Alternative Email"
                  label="Alternative Email"
                  id="alternateEmail"
                  htmlFor="alternateEmail"
                  value={altEmail}
                  setValue={(event) => setAltEmail(event.target.value)}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.altEmail}
                  </span>
                </small>
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={classNames([styles.inputBox, styles.wp25])}>
                <InputBox
                  placeholder="Primary Phone"
                  label="Primary Phone *"
                  id="primaryPhone"
                  htmlFor="primaryPhone"
                  value={primaryPhone}
                  setValue={(event) => setPrimaryPhone(event.target.value)}
                  ref={primaryPhoneRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.primaryPhone}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp25])}>
                <InputBox
                  placeholder="Student/Faculty ID"
                  label="Student/Faculty ID *"
                  id="studentFacultyID"
                  htmlFor="studentFacultyID"
                  value={individualID}
                  setValue={(event) => setIndividualID(event.target.value)}
                  ref={individualIDRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.studentFacultyID}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp25])}>
                <InputBox
                  placeholder="School"
                  label="School"
                  id="school"
                  htmlFor="school"
                  readOnly
                  value={school}
                  setValue={(event) => setSchool(event.target.value)}
                />
              </div>
              <div className={classNames([styles.inputBox, styles.wp25])}>
                <InputBox
                  placeholder="Testing"
                  label="Program"
                  id="program"
                  htmlFor="program"
                  readOnly
                  value={program}
                  setValue={(event) => setProgram(event.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={classNames([styles.inputBox, styles.wp50])}>
                <InputBox
                  placeholder="Emergency Contact Name"
                  label="Emergency Contact Name *"
                  id="emergencyContactName"
                  htmlFor="emergencyContactName"
                  value={emergencyContact}
                  setValue={(event) =>
                    setEmergencyContactName(event.target.value)
                  }
                  ref={emergencyContactNameRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.emergencyContactName}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp50])}>
                <InputBox
                  placeholder="Emergency Contact Phone"
                  label="Emergency Contact Phone *"
                  id="emergencyContactPhone"
                  htmlFor="emergencyContactPhone"
                  value={emergencyPhone}
                  setValue={(event) =>
                    setEmergencyContactPhone(event.target.value)
                  }
                  ref={emergencyContactPhoneRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.emergencyContactPhone}
                  </span>
                </small>
              </div>
            </div>
            <hr />
            <div className={styles.inputRow}>
              <div className={classNames([styles.inputBox, styles.wp35])}>
                <InputBox
                  placeholder="Permanent Address"
                  label="Permanent Address *"
                  id="permanentAddress"
                  htmlFor="permanentAddress"
                  value={address1}
                  setValue={(event) => setAddress1(event.target.value)}
                  ref={address1Ref}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.permanentAddress}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp35])}>
                <InputBox
                  placeholder="City"
                  label="City *"
                  id="city"
                  htmlFor="city"
                  value={city}
                  setValue={(event) => setCity(event.target.value)}
                  ref={cityRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.city}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp15])}>
                <InputBox
                  placeholder="STATE"
                  label="State *"
                  id="state"
                  htmlFor="state"
                  value={state}
                  setValue={(event) => setState(event.target.value)}
                  ref={stateRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.state}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp15])}>
                <InputBox
                  placeholder="ZIP"
                  label="Zipcode *"
                  id="zipcode"
                  htmlFor="zipcode"
                  value={zip}
                  setValue={(event) => setZip(event.target.value)}
                  ref={zipRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.zipcode}
                  </span>
                </small>
              </div>
            </div>
            <div className={styles.inputRow}>
              <div className={classNames([styles.inputBox, styles.wp15])}>
                <FormDatePicker
                  label="Birthdate *"
                  id="birthdate"
                  htmlFor="birthdate"
                  dateString={dob}
                  setDate={setDOB}
                  ref={dobRef}
                  dateFormat={dateFormat}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.dateOfBirth}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp15])}>
                <SocialSecurityInput
                  label="Social Security *"
                  id="socialSecurity"
                  htmlFor="socialSecurity"
                  value={ssn}
                  setValue={setSSN}
                  ref={ssnRef}
                />
                <small className={styles.error}>
                  <span className={styles.fieldValidationError}>
                    {errors.socialSecurity}
                  </span>
                </small>
              </div>
              <div className={classNames([styles.inputBox, styles.wp15])}>
                <QuestionToggle
                  label="Previous Name?"
                  value={hasPreviousNames ? "yes" : "no"}
                  onClick={(yesOrNo) => {
                    setHasPreviousNames(yesOrNo == "yes");
                  }}
                />
              </div>
              <div className={classNames([styles.inputBox, styles.wp15])}>
                <div>
                  <button
                    type="button"
                    className={styles.buttonAddAlias}
                    id="add_alias"
                    onClick={(e) => addPrevious(e)}
                  >
                    Add Previous Name
                  </button>
                </div>
              </div>
            </div>

            {previousNames.map((row, index) => (
              <div key={`prevname_${index}`} className={styles.inputRow}>
                <div className={classNames([styles.inputBox, styles.wp50])}>
                  <InputBox
                    id={`previousFirstName${index}`}
                    placeholder="Previous First Name"
                    label="Previous First Name"
                    htmlFor="Previous First Name"
                    value={row.NameFirst}
                    setValue={(event) =>
                      handlePreviousItemChange(
                        "NameFirst",
                        index,
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className={classNames([styles.inputBox, styles.wp50])}>
                  <InputBox
                    id={`previousLastName${index}`}
                    placeholder="Previous Last Name"
                    label="Previous Last Name"
                    htmlFor="Previous Last Name"
                    value={row.NameLast}
                    setValue={(event) =>
                      handlePreviousItemChange(
                        "NameLast",
                        index,
                        event.target.value
                      )
                    }
                  />
                </div>
              </div>
            ))}
            <hr />
          </div>
          <Button
            text={!loading ? "Update Profile" : "Update Profile ..."}
            type={"submit"}
            disabled={!dirty || loading}
          />
        </div>
      </form>
    </div>
  );
};

const paragraphText = [
  "Clinical Placement Organizations depend on the information below to meet legal and regulatory requirements during the course of your clinical learning experience. A failure to provide true, accurate or complete information, or a false confirmation of information, may be considered a fraud in the clinical placement process, and result in loss of a placement or other negative consequences. Access to this information is strictly controlled as described in CPNW Privacy and Terms of Use policies",
];
const dateFormat = "yyyy-MM-dd";

export default MyProfile;
