export const StaticRoutes = {
    Root: "/",

    GetStarted: "/GetStarted",
    GetStartedSchools: "/GetStarted/Schools",
    GetStartedStudents: "/GetStarted/Students",
    GetStartedHealthcare: "/GetStarted/Healthcare",

    AboutUs: "/AboutUs",
    AboutUsHealthcarePartners: "/AboutUs/HealthcarePartners",
    AboutUsParticipatingPrograms: "/AboutUs/ParticipatingPrograms",
    AboutUsNursingPlacement: "/AboutUs/NursingPlacement",


    AccountRegister: "/Account/Register",

    MyDashboard: "/",

    OurServices: "/OurServices",

    FAQ: "/FAQ",

    //Clinical Prerequisites
    Passport: "/Passport",
    SiteRequirements: "/SiteRequirements",
    eLearning: "/eLearning",

    //My Records
    eLearningResults: "/eLearningResults",
    OIGSAM: "/OIGSAM",

    //My Account
    MyAccount: "/MyAccount",
    MyProfile: "/MyProfile",
    MySecuritySettings: "/MySecuritySettings",
    MyDemographics: "/MyDemographics",

    //Admin
    Reports: "/Reports",
    Assignments: "/Assignments",
    Users: "/Users",

    //AccountRegistrationProfile
    AccountRegistrationProfile: "/Account/RegistrationProfile",
}