import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./DashboardTablePagination.module.css";

const DashboardTablePagination = ({ onPageChange, total, perPage }) => {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    // Calculate the last page based on total and perPage
    const calculatedLastPage = Math.ceil(total / perPage);
    setLastPage(calculatedLastPage);

    // Default to page 1 whenever total or perPage change
    setPage(1);
  }, [total, perPage]);

  const handlePrevious = () => {
    if (page > 1 && onPageChange) {
      setPage(page - 1);
      onPageChange(page - 1)
    }
  };

  const handleNext = () => {
    if (page < lastPage && onPageChange) {
      setPage(page + 1);
      onPageChange(page + 1)
    }
  };

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
    onPageChange(pageNum);
  };

  // Verify current page is logged to the console whenever the component re-renders
  // console.log(`Navigated to page ${page}`);

  return (
    total > 0 && (
      <div className={styles.pagination}>
        <div className={styles.info}>
          {page === lastPage
            ? `Showing ${
                (page - 1) * perPage + 1
              } to ${total} of ${total} entries`
            : `Showing ${(page - 1) * perPage + 1} to ${Math.min(
                page * perPage,
                total
              )} of ${total} entries`}
        </div>
        <div className={styles.buttons}>
          <span
            onClick={handlePrevious}
            className={`${styles.button} ${page === 1 && styles.disabled}`}
          >
            Previous
          </span>
          {[...Array(lastPage).keys()].map((pageNum) => (
            <span
              key={pageNum}
              onClick={() => handlePageChange(pageNum + 1)}
              className={`${styles.button} ${
                pageNum + 1 === page ? styles.active : ""
              }`}
            >
              {pageNum + 1}
            </span>
          ))}
          <span
            onClick={handleNext}
            className={`${styles.button} ${
              page === lastPage && styles.disabled
            }`}
          >
            Next
          </span>
        </div>
      </div>
    )
  );
};

DashboardTablePagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
};

export default DashboardTablePagination;
