import React, { useState, useRef, useEffect } from 'react';
import placeholderImage from '../../assets/profile-image.png';
import { Button } from "@cpnw/ui";
import PropTypes from 'prop-types';
import styles from './DashboardDemographicsImage.module.css';

const DashboardDemographicsImage = ({ changeImageItem, image }) => {
  const [imagePreview, setImagePreview] = useState(image || placeholderImage);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      changeImageItem(file);
    };
    reader.readAsDataURL(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (image) {
      setImagePreview(image);
    }
  }, [image]);

  return (
    <div className={styles.container}>
      {imagePreview && <img src={imagePreview} alt="Preview" style={{ marginTop: '10px', marginBottom: '15px', width: '100%', maxWidth: '100%' }} />}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="upload-image"
      />
      <Button className={styles.square_button} text="Upload File" onClick={handleButtonClick} />
    </div>
  );
};

DashboardDemographicsImage.propTypes = {
  changeImageItem: PropTypes.func.isRequired,
  image: PropTypes.string,
};

export default DashboardDemographicsImage;



