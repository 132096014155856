import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./DashboardSortTableHeader.module.css";

const DashboardSortTableHeader = ({ value, onChange, title, width }) => {
  const [sortOrder, setSortOrder] = useState("");

  // Handle sorting behavior for 'title'
  const handleTitleClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    onChange(newSortOrder);
  };

  // Handle sorting behavior for 'up arrow'
  const handleAscClick = () => {
    if (sortOrder !== "asc") {
      setSortOrder("asc");
      onChange("asc");
    }
  };

  // Handle sorting behavior for 'down arrow'
  const handleDescClick = () => {
    if (sortOrder !== "desc") {
      setSortOrder("desc");
      onChange("desc");
    }
  };

  return (
    <div className={styles.header} style={{ width }}>
      {/* Make the title clickable */}
      <span onClick={handleTitleClick}>{title}</span>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon
          icon={faCaretUp}
          onClick={handleAscClick}
          className={value === "asc" ? styles.active : styles.inactive}
        />
        <FontAwesomeIcon
          icon={faCaretDown}
          onClick={handleDescClick}
          className={value === "desc" ? styles.active : styles.inactive}
        />
      </div>
    </div>
  );
};

DashboardSortTableHeader.propTypes = {
  value: PropTypes.oneOf(["", "asc", "desc"]).isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, //can pass fontawsome icon as well here (object)
  width: PropTypes.number.isRequired,
};

export default DashboardSortTableHeader;
