import React from 'react';
import { useState } from 'react';
import PropTypes from "prop-types";
import styles from "./DashboardResultHeader.module.css";

import pdfIconBrown from "../../assets/fa-file-pdf-brown.svg";
import pdfIconBlue from "../../assets/fa-file-pdf-blue.svg";

export default function DashboardResultHeader({name, id, src}){
    const [iconHover, setIconHover] = useState(false);

return (
    <>
        <div className={styles.results_header_row}>
            <strong className={styles.results_header_label}>
                Results for
            </strong>
            <div>
                {name}
            </div>

            <strong className={styles.results_header_label}>
                ID
            </strong>
            <div>
                {id}
            </div>
            
            <a href={src} className={styles.results_download} onMouseEnter={() => setIconHover(true)} onMouseLeave={() => setIconHover(false)}>
                <div className={styles.icon}><img src={iconHover ? pdfIconBlue : pdfIconBrown}></img></div>
                Download Results
            </a>

            <div>
            </div>
        </div>
    </>
);
};

DashboardResultHeader.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
}