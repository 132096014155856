import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardGroupAssigningModal.module.css";
import { Modal, HeaderSection, SelectBox, Button } from "@cpnw/ui";
import FormDatePicker from "components/FormDatePicker";

export const DashboardGroupAssigningModal = ({ isOpen, onClose, locationOptions, onAssign, isEditGroup = false, groupData = {} }) => {

    const onCloseModal = () => {
        onClose();
    }

    const GroupAssigningContent = ({ onAssign }) => {

        const [location, setLocation] = useState(isEditGroup ? groupData.location : locationOptions.defaultValue);
        const [startDate, setStartDate] = useState(isEditGroup ? groupData.startDate : '');
        const [endDate, setEndDate] = useState(isEditGroup ? groupData.endDate : '');

        useEffect(() => {
            if (isDateAfter(startDate, endDate)) {
                setEndDate(startDate);
            }
        }, [startDate, endDate]);

        const isDateAfter = (startDate, endDate) => {
            // Convert string dates to Date objects
            const sDate = new Date(startDate);
            const eDate = new Date(endDate);

            // Check if sDate is after eDate
            return sDate > eDate;
        };

        const onClickAssignGroupButton = (e) => {
            const groupData = {
                location: location,
                startDate: startDate,
                endDate: endDate
            }
            onAssign(groupData);
        }

        const onClickUpdateGroupButton = (e) => {
            const groupData = {
                location: location,
                startDate: startDate,
                endDate: endDate
            }
            onAssign(groupData);
        }

        const onClickDeleteGroupButton = (e) => {
            const groupData = {
                location: location,
                startDate: startDate,
                endDate: endDate
            }
            onAssign(groupData);
        }

        return (
            <>
                <div className={styles.flexContainer}>
                    <div className={classNames(styles.header, styles.flexItem)}><HeaderSection header={"Group Assigning"} /></div>
                    <div className={classNames(styles.close, styles.flexItem)} onClick={() => onCloseModal()}><div className={styles.timeIcon}>x</div></div>
                    <div className={classNames(styles.help, styles.row)}>
                        If you need assistance contact technical support at <a href="mailto:support@cpnw.org">support@cpnw.org</a>
                    </div>
                    <div className={classNames(styles.row)}>
                        <label htmlFor="location">Hospital Location:
                            <SelectBox
                                id="location"
                                selected={location}
                                onChange={(value) => {
                                    setLocation(value);
                                }}
                                placeholder={""}
                                options={locationOptions.options}
                            /></label>
                    </div>
                    <div className={classNames(styles.row)}>
                        <label htmlFor="startDate">Start Date:
                            <FormDatePicker
                                id="startDate"
                                placeholder={"MM/DD/YYYY"}
                                setDate={(value) => {
                                    setStartDate(value);
                                }}
                                dateString={startDate}
                                dateFormat='MM/dd/yyyy'
                            /></label>
                    </div>
                    <div className={classNames(styles.row)}>
                        <label htmlFor="endDate">End Date:
                            <FormDatePicker
                                id="endDate"
                                placeholder={"MM/DD/YYYY"}
                                setDate={(value) => {
                                    setEndDate(value);
                                }}
                                dateString={endDate}
                                dateFormat='MM/dd/yyyy'
                                minDateString={startDate}
                            /></label>
                    </div>
                    <div className={classNames(styles.row)}>
                        {!isEditGroup && <Button onClick={(e) => onClickAssignGroupButton(e)} text="Assign Group" />}
                        {isEditGroup && <Button onClick={(e) => onClickUpdateGroupButton(e)} text="Update Group" />}
                        {isEditGroup && <Button onClick={(e) => onClickDeleteGroupButton(e)} text="Delete Group" />}
                    </div>
                </div>
            </>
        )
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onCloseModal} className={classNames(styles.dashboardGroupAssigningModal)} children={<GroupAssigningContent onAssign={onAssign} />} />
    );
};

DashboardGroupAssigningModal.propTypes = {
    onAssign: PropTypes.func.isRequired,
}

export default DashboardGroupAssigningModal;


