import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useMemo } from "react";

export function useAccount() {
    const { setToken } = Auth.useContainer()

    const login = async ({ email, password }) => {
        // TODO: Add back when handling real API
        return await fetch(environment.apiBase + `/cpnw/account/login`, {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ email, password })
        }).then((res) => res.json())
        .then(res=>{
            let token = res.token
            setToken(token)
            window.location.href = window.location.href
            return token
        })
    }

    const logout = async () => {
        return await fetch(environment.apiBase + `/cpnw/account/logout`, {
            method: "get",
            credentials: 'include',
        })
        .then(res=>{
            setToken(null)
            window.location.href = environment.homeUrl
        })
        
    }

    const { data: meData } = useQuery(
        ["MeGet"],
        async () =>
          await makeAuthedRequest(`/cpnw/me`, {
            method: "GET",
          }).then(async (res) => {
            if (res.ok) {
              return res.json();
            } else {
              const body = await res.json();
              throw new Error(body.message);
            }
          })
      );

    const userRoles = useMemo(()=>{
        if(meData){
            return meData.roles
        }
        return []
    },[meData])

    const conAssignments = useMemo(()=>{
        if(meData){
            return meData.conAssignments
        }
        return []
    },[meData])

    return {
        login,
        logout,
        meData,
        userRoles,
        conAssignments
    };
}
export const AccountContainer = createContainer(useAccount);
