import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardContactInformationModal.module.css";
import { Modal, HeaderSection } from "@cpnw/ui";
import ProfileHeadshot from "../../images/profile-headshot.svg";

export const DashboardContactInformationModal = ({ isOpen, onClose, contact }) => {
    const onCloseModal = () => {
        onClose();
    }

    const ContactInformationContent = ({ contact }) => {
        return (
            <>
                <div className={styles.flexContainer}>
                    <div className={classNames(styles.header, styles.flexItem)}><HeaderSection header={"Clinical Assignments"} /></div>
                    <div className={classNames(styles.close, styles.flexItem)} onClick={() => onCloseModal()}>
                        <div className={styles.timeIcon}>x</div>
                    </div>
                    <div className={classNames(styles.info, styles.flexItem)}>
                        <ul className={styles.noBullet}>
                            <li id="modalName">Name: <span>{contact.name} </span></li>
                            <li id="modalEmail">Email: <span>{contact.email}</span></li>
                            <li id="modalPhone">Phone: <span>{contact.phone}</span></li>
                            <li id="modalAddress">Address: <span> {contact.address}</span></li>
                            <li id="modalEmergencyContact">Emergency Contact: <span>{contact.emergencyContact}</span></li>
                            <li id="modalEmergencyPhone">Emergency Contact Phone: <span>{contact.emergencyContactPhone}</span></li>
                            <li id="modalSchool">School: <span>{contact.location}</span></li>
                            <li id="modalProgram">Program: <span>{contact.program}</span></li>
                            <li id="modalSID">SID/EID: <span>{contact.sIDEID}</span></li>
                        </ul>
                    </div>
                    <div className={classNames(styles.image, styles.flexItem)}>
                        <img src={ProfileHeadshot} height="133px" width="133px" />
                    </div>
                </div>
            </>
        )
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onCloseModal}
            modalClassName={styles.customWidthAndHeight}
            className={classNames(styles.dashboardContactInformationModal)}
            children={<ContactInformationContent contact={contact} />} />
    );
};

DashboardContactInformationModal.propTypes = {
    contact: PropTypes.object.isRequired,
}


