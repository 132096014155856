const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

// Convert a CSS style string to a camelCase style object
export const convertStyleStringToObject = (styleString) => {
  return styleString.split(";").reduce((acc, style) => {
    if (style.trim()) {
      const [key, value] = style.split(":");
      acc[toCamelCase(key.trim())] = value.trim();
    }
    return acc;
  }, {});
};
