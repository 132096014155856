import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery } from "react-query";
import { makeAuthedRequest, makeAuthedMultipartRequest } from "utils/makeAuthedRequest";

export function useCpnw() {
  /*
    Sample data:
    [
      {
          "facilityIDPK": 357,
          "facilityName": "CPNW HEALTHCARE"
      },
      {
          "facilityIDPK": 620,
          "facilityName": "CPNW MEDICAL CENTER"
      }
    ]
    */
  const { data: siteRquirementsData } = useQuery(
    ["SiteRequirementsGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/SiteRequirements`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
  );

  const { data: myProfile, refetch: refetchMyProfile } = useQuery(
    ["ProfileGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/myprofile`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.error);
        }
      })
  );

  const myProfilePost = async (profile) =>
    await makeAuthedRequest(`/cpnw/myprofile`, {
      method: "POST",
      body: JSON.stringify(profile)
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw body.error;
      }
    });

  const { data: learningModulesData } = useQuery(
    ["LearningModulesGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/LearningModules`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
  );

  const { data: noticeLatestData } = useQuery(
    ["NoticeLatestGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/Notice/Latest`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
  );

  const takeQuiz =
    async (id) =>
      await makeAuthedRequest(`/cpnw/TakeQuiz?id=${id}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })

  const { data: learningResultsData } = useQuery(
    ["LearningResultsGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/LearningResults`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
  );

    const { data: mySecuritySettingsData } = useQuery(
      ["MySecuritySettingsGet"],
      async () => {
        const res = await makeAuthedRequest(`/cpnw/MySecuritySettings`, {
          method: "GET"
        });
    
        if (res.ok) {
          const result = await res.json();
          return result; 
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }
    );

    const { data: SMSProvidersData } = useQuery(
      ["SMSProvidersGet"],
      async () => {
        const res = await makeAuthedRequest(`/cpnw/SMSProviders`, {
          method: "GET"
        });
    
        if (res.ok) {
          const result = await res.json();
          return result; 
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }
    );

    const updateSecuritySettings = async (securitySettings) => {
      const res = await makeAuthedRequest(`/cpnw/MySecuritySettings`, {
        method: "POST",
        body: JSON.stringify(securitySettings)
      });
  
      if (res.ok) {
        const result = await res.json();
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

    const submitDemoGraphics = async (formData) => {
      const res = await makeAuthedMultipartRequest(`/cpnw/MyDemographics`, formData);
  
      if (res.ok) {
        const result = await res.json();
        return result; 
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

    const { data: myDemoGraphicsData } = useQuery(
      ["MyDemoGraphicsGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/MyDemographics`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        })
    );

    const { data: oigSamData, refetch: oigSamRefetch } = useQuery(
      ["OIGSAM"],
      async () =>
        await makeAuthedRequest(`/cpnw/OIGSAM`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
        {
          enabled: false, // Prevent automatic fetching
        }
    );

  return {
    siteRquirementsData,
    learningModulesData,
    noticeLatestData,
    takeQuiz,
    myProfile,
    refetchMyProfile,
    myProfilePost,
    learningResultsData,
    mySecuritySettingsData,
    SMSProvidersData,
    updateSecuritySettings,
    submitDemoGraphics,
    myDemoGraphicsData,

    oigSamData,
    oigSamRefetch
  };
}
export const CpnwContainer = createContainer(useCpnw);
