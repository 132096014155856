import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FreshDeskWidget from "widgets/FreshDeskWidget";
import Layout from "pages/Layout";
import { StaticRoutes } from "pages/routes";
import ClinicalPassport from "pages/DashboardClinicalPrerequisites/pages/DashboardClinicalPassport"
import SiteRequirements from "pages/DashboardClinicalPrerequisites/pages/SiteRequirements";
import ELearningResults from "pages/DashboardMyRecords/pages/eLearningResults";
import ELearning from "pages/DashboardClinicalPrerequisites/pages/eLearning";
import MySecuritySettings from "pages/DashboardMyAccountPage/pages/MySecuritySettings";
import DashboardMyDemographics from "pages/DashboardMyAccountPage/pages/DashboardMyDemographics";
import MyProfile from "pages/DashboardMyAccountPage/pages/DashboardMyProfile";
import TakeQuizPage from "pages/TakeQuizPage";
import Assignments from "pages/DashboardAdminPage/pages/Assignments";
import environment from "environment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MyDashboardPage from "pages/MyDashboardPage";
import OIGSAMPage from "pages/DashboardMyRecords/pages/OIGSAMPage";

const NotFoundRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const externalBaseUrl = environment.homeUrl;
    const targetUrl = `${externalBaseUrl}${location.pathname}${location.search}${location.hash}`;
    window.location.href = targetUrl;
  }, [location]);

  return (
    <></>
  );
};

function App() {
  return (
    <>
      <FreshDeskWidget />
      <Router>
        <Switch>
          <Route exact path="/">
            <Layout>
              <MyDashboardPage/>
            </Layout>
          </Route>
          <Route path="/trainingmodules/*">
            <TakeQuizPage/>
          </Route>
          <Route>
            <Layout>
              <Switch>
                <Route path={StaticRoutes.AccountRegistrationProfile} component={MyProfile} />              
                <Route path={StaticRoutes.Passport} component={ClinicalPassport} />
                <Route path={StaticRoutes.SiteRequirements} component={SiteRequirements}/>
                <Route path={StaticRoutes.eLearning} component={ELearning}/>
                <Route path={StaticRoutes.eLearningResults} component={ELearningResults}/>
                <Route path={StaticRoutes.OIGSAM} component={OIGSAMPage}/>
                <Route path={StaticRoutes.MySecuritySettings} component={MySecuritySettings}/>
                <Route path={StaticRoutes.MyDemographics} component={DashboardMyDemographics}/>
                <Route path={StaticRoutes.MyProfile} component={MyProfile}/>
                <Route path={StaticRoutes.Reports} component={Assignments}/>
                <Route path={StaticRoutes.Assignments} component={Assignments}/>
                <Route path={StaticRoutes.Users} component={Assignments}/>

                <Route component={NotFoundRedirect} />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
