import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";

export function useAdmin() {
  const takeQuiz = 
    async (id) =>
      await makeAuthedRequest(`/cpnw/TakeQuiz?id=${id}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })

    const { data: adminUsersData } = useQuery(
      ["AdminUsersGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/Users`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        })
    );

  return {
    adminUsersData
  };
}
export const AdminContainer = createContainer(useAdmin);
